import React, {useState, useEffect, useRef, Fragment} from 'react'
import {TextField,  Button,Divider, Container, Grid, Typography, InputAdornment, Table, TableHead, TableBody, TableCell
, TableRow} from '@material-ui/core'
import EmailIcon from '@material-ui/icons/Email';
import LockIcon from '@material-ui/icons/Lock';
import Conexao from '../../providers/Conexao'
import ConexaoB from '../../providers/ConexaoB'
import Swal from 'sweetalert2'
import IconeBateria from '@material-ui/icons/BatteryCharging20'
import {withRouter} from 'react-router-dom'
import Principal from '../Template/TemplateRevenda';
import {Breadcrumbs} from '@material-ui/core'
import {Link } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress';

import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import RemoveIcon from '@material-ui/icons/DeleteForever';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import LabeData from '../../components/LabelData'
import Tooltip from '@material-ui/core/Tooltip';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import PersonIcon from '@material-ui/icons/Person';
import TablePagination from '@material-ui/core/TablePagination';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import Build from '@material-ui/icons/Build'
import ComputerOutlined from '@material-ui/icons/ComputerRounded';
import ConexaoInventory from  '../../providers/ConexaoInventory'
import iconePlacaMae2 from './imagens/iconePlacaMae2.png'
import IconeProcessador from './imagens/iconeProcessador.png'
import IconeMemoria from './imagens/iconeMemoria.png'
import IconeHDD from './imagens/iconeHDD.png'
import IconeVideo from './imagens/iconePlacaVideo.png'
import IconeMonitor from './imagens/iconeMonitor.png'
import IconeRede from './imagens/inconePlacaRede.png'
import IconeUSB from './imagens/iconeUSB.png'
import IconeTeclado from './imagens/iconeTeclado.png'
import IconeMousefrom  from './imagens/iconeMouse.png'
import IconeDesktop from './imagens/iconeDesktop.png'
import IconeNote from './imagens/iconeNotebook.png'
import IconeVM from './imagens/iconeVirtualMachine.png'
import DisplayToner from '../../components/DisplayToner'
import {LinearProgress} from '@material-ui/core'
import {Alert, AlertTitle} from '@material-ui/lab'
import ModalMapa from './ModalMapa';
import Brush from '@material-ui/icons/Brush';
import DeleteForever from '@material-ui/icons/DeleteForever';
import Save from '@material-ui/icons/Save';
import GraficoUso from './GraficoUso'
const DataInventoryDetails = (props)=>{
    const classes = useStyles();
    const [openModal, setOpenModal]= useState(false)
    const [loading, setLoading] = useState(false)
    const [colunaFiltro, setColunaFiltro] = useState('Cliente')
    const [parametroFiltro, setParametroFiltro] = useState([])
    const [filtro, setFiltro] = useState([])
    const [dataTecnico, setDataTecnico] = useState([])
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [dcas, setDcas] = useState([])
    const [dcasFiltro, setDcasFiltro] = useState([])
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(100);
    const [ registros, setRegistros] = useState(0)
    const refTipoDataPesquisa = React.createRef();
    const refDataPesquisa = React.createRef();
    const refChaveInstalacao = React.createRef();
    const refRazaoSocial = React.createRef();
    const [inventory, setInventory]= useState([])
    const [modeloAlternativo, setModeloAlternativo]= useState('')
    const [modoEdicao, setModoEdicao]= useState(false)
    const [mensagemDias, setMensagemDias]= useState('')


    const renderTipo = (tipo) =>{
        
        switch(tipo){
            case 'Desktop': return <img src ={IconeDesktop} width={95} />;
            case 'Notebook': return <img src ={IconeNote}  width={95} />;
            case 'Virtual Machine': return <img src ={IconeVM}  width={95} />;
            default:return <img src ={IconeDesktop} width={95}/>;
        }
    }


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };



    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

  

    useEffect(()=>{
        getOne();
    }, [])

   


    const  getOne = () =>{
        setLoading(true)
        ConexaoInventory.get('InventoryController/getOne?id='+props.location.state.inventory._id).then( response =>{
            if(response.data.status == 'OK'){
                setInventory(response.data.payload[0])
                
            }else{
             
                setInventory([])
            }
            setLoading(false)
        }).catch( error =>{
       
            setInventory([])
            setLoading(false)
        });
    }

   

    const renderUltimoAcesso = (acesso) =>{
        if(acesso == 'printerconnect-app-collector'){
            return <PersonIcon color='primary' />
        }else{
            return <SettingsApplicationsIcon  color='primary'/>
        }
    }

    const bytesToGigabytes = (bytes) =>{
        const gigabytes = bytes / (1024 ** 3);
        return gigabytes.toFixed(2); // Arredonda para 2 casas decimais
    }


    const renderData = (data) =>{
        var teste = data.split('T')
        return teste[0]+' '+teste[1].substr(0,8)
    }

    const renderMSGDias = (parametro ) =>{
        
        const dias = parametro.split('-')

        if(dias[0] > 0){
            return <Alert severity='error' style ={{marginTop :'5px'}}>
            <AlertTitle>DCA Sem comunicação</AlertTitle>
            <Typography>* Seu DCA esta há {dias[0]} dia(s) sem comunicação com o Datacenter. Verifique!</Typography>
        </Alert> 

        }else if(dias[1] >= 6 ){
            return <Alert severity='warning' style ={{marginTop :'5px'}}>
            <AlertTitle>DCA Sem comunicação</AlertTitle>
            <Typography>* Seu DCA esta há {dias[1]} hora(s) sem comunicação com o Datacenter. Verifique!</Typography>
        </Alert> 
        }
        
    }

    const renderBateria = (bateria) =>{
        if(bateria && bateria.Name){
            return <div style={{display:'flex'}}>
                <div style ={{width : '70px'}}><IconeBateria style={{fontSize : '75px'}}/></div>
                <div style ={{width : '500px'}}>
                <Typography variant='caption'>Modelo: {bateria.Name + ( bateria.Chemistry ? ' ('+bateria.Chemistry+')' : '')} </Typography><br/>
                <Typography variant='caption'>Carga Atual: {bateria.EstimatedChargeRemaining}% </Typography><br/>
                <Typography variant='caption'>Voltage: {bateria.Voltage + (bateria.BatteryStatus ? ' Status : ' + bateria.BatteryStatus : '')}</Typography><br/>
                </div>
                
                
            </div>
        }
    }
    return <Principal atualizar = {loading} getData={getOne} breadcrumbs={
        <Breadcrumbs aria-label="breadcrumb">  
        <Typography>Você esta em:</Typography>          
            <Link color="inherit" to={'#'} >
                Inventory Detalhes
            </Link>                
        </Breadcrumbs>
    }>
        <div>
            <div style ={{display: 'flex', padding :'10px',paddingBottom : '20px', borderRadius :'5px', backgroundColor : '#fff'}}>
                <div style ={{width : '90%'}}>
                    <div style={{display:'flex'}}>
                        <div style ={{width : '10%'}}>
                            {
                                renderTipo(inventory.typeDevice)
                            }
                        </div>
                        <div style ={{width : '90%'}}>
                            <Typography variant='h3'>
                            {inventory.hostName ? inventory.hostName : '* não disponível'}
                            </Typography>
                            <Typography variant='caption'>ID DCA: {inventory._id}</Typography><br/>
                            <Typography variant='caption'>IP Remoto: {inventory.publicIP}</Typography><br/>
                            
                            {
                                inventory.Posicao ? <a href ={'https://www.google.com.br/maps/@'+inventory.Posicao.latitude+','+inventory.Posicao.longitude+',8367m/data=!3m1!1e3?entry=ttu&g_ep=EgoyMDI0MDgyOC4wIKXMDSoASAFQAw%3D%3D'} target='_blank'>Ver Localização recente</a> : null}
                                <div>
                                    {
                                        renderBateria(inventory.Battery)
                                    }
                                </div>
                        </div>
                    </div>
                    
                   {
                    renderMSGDias(props.location.state.dias)
                   }
                
                    
                </div>
                <div style ={{width : '10%'}}>


                <Link to={{pathname : '/CadastroComputadoresInventory', state:{
                            ...props.location.state
                        
                        }}}>
                       
                       X Fechar
                        </Link>
                </div>
            </div>
           
            <div style ={{ padding :'10px',marginTop : '10px', paddingBottom : '20px', borderRadius :'5px', backgroundColor : '#fff'}}>
            <Grid container spacing={1} style ={{marginTop :'5px'}}>
                <Grid item xs ={3}>
                <Typography variant='caption'>
                        Modelo
                    </Typography>
                    {
                        modoEdicao == true ? <React.Fragment><TextField
                                    fullWidth                                   
                                    variant='outlined'
                                    value={modeloAlternativo}
                                    onChange={(e)=>setModeloAlternativo(e.target.value)}
                                    size='small'
                                    InputProps={{
                                        endAdornment: ( <InputAdornment position='end'>
                                        <React.Fragment>
                                            <Button style={{marginLeft : '3px'}} onClick={()=>{
                                                setModoEdicao(false)
                                            }}><DeleteForever style={{color:'red'}}/></Button>
                                            <Button style={{marginLeft : '3px'}} onClick={()=>{
                                                Swal.fire({
                                                    title : 'Datacount', 
                                                    text : 'Deseja gravar as alterações?', 
                                                    icon : 'question', 
                                                    showCancelButton : true, 
                                                    showConfirmButton : true
                                                }).then(resposta=>{
                                                    if(resposta.isConfirmed){

                                                        ConexaoInventory.post('InventoryController/updateOneInterface', {
                                                            _id : inventory._id, 
                                                            modeloAlternativo : modeloAlternativo
                                                        }).then(response=>{

                                                        }).catch(error=>{

                                                        })
                                                        setModoEdicao(false)
                                                    }else{
                                                        setModoEdicao(false)
                                                    }
                                                })
                                               
                                            }}><Save style={{color:'green'}}/></Button>
                                        </React.Fragment>
                                        </InputAdornment> )
                                        
                                    }}
                                    /> 
                                    
                                    </React.Fragment> : <Typography variant='subtitle1'  >
                                    { inventory.modeloAlternativo ? inventory.modeloAlternativo : (inventory.Modelo ? inventory.Modelo.Manufacturer + ' / '+inventory.Modelo.Model : '* não disponível')}<Button variant='contained' style={{marginLeft : '3px'}} onClick={()=>{
                                        if(inventory.modeloAlternativo){
                                            setModeloAlternativo(inventory.modeloAlternativo)
                                        }else{
                                            setModeloAlternativo(inventory.Modelo.Model)
                                        }
                                        setModoEdicao(true)
                                    }}><Brush /></Button>
                                    </Typography>
                                     
                                
                    }
                    
                   

                    
                </Grid>

                <Grid item xs ={3}>
                    <Typography variant='caption'>
                        Número de Série
                    </Typography>
                    <Typography variant='subtitle1'>
                    {inventory.Bios ? inventory.Bios.SerialNumber : '* não disponível'}
                    </Typography>
                </Grid>

                <Grid item xs ={2}>
                    <Typography variant='caption'>
                        Fabricante
                    </Typography>
                    <Typography variant='subtitle1'>
                    {inventory.Modelo ? inventory.Modelo.Manufacturer : '* não disponível'}
                    </Typography>
                </Grid>

                <Grid item xs ={3}>
                    <Typography variant='caption'>
                        Versão Bios
                    </Typography>
                    <Typography variant='subtitle1'>
                    {inventory.Bios ? inventory.Bios.Version+' / '+inventory.Bios.Manufacturer : '* não disponível'}
                    </Typography>
                </Grid>

                
            </Grid>
            <Grid container spacing={1} style ={{marginTop :'5px'}}>
                <Grid item xs ={3}>
                    <Typography variant='caption'>
                        Sistema Operacional
                    </Typography>
                    <Typography variant='subtitle1'>
                        {inventory.OperatingSystem ? inventory.OperatingSystem.Name  : '* não disponível'}
                    </Typography>
                </Grid>
                <Grid item xs ={2}>
                    <Typography variant='caption'>
                        Arquitetura
                    </Typography>
                    <Typography variant='subtitle1'>
                        {inventory.OperatingSystem ? inventory.OperatingSystem.Architecture  : '* não disponível'}
                    </Typography>
                </Grid>

                <Grid item xs ={2}>
                    <Typography variant='caption'>
                        Versão
                    </Typography>
                    <Typography variant='subtitle1'>
                        {inventory.OperatingSystem ? inventory.OperatingSystem.Version  : '* não disponível'}
                    </Typography>
                </Grid>

                <Grid item xs ={2}>
                    <Typography variant='caption'>
                        Build
                    </Typography>
                    <Typography variant='subtitle1'>
                        {inventory.OperatingSystem ? inventory.OperatingSystem.BuildNumber  : '* não disponível'}
                    </Typography>
                </Grid>

            </Grid>
            </div>
            <div style ={{ padding :'10px',marginTop : '10px', paddingBottom : '20px', borderRadius :'5px', backgroundColor : '#fff'}}>
                <Grid container spacing={1} style ={{marginTop :'5px'}}>
                    <Grid item xs ={3}>
                        <Typography variant='caption'>
                            Data 1º Leitura
                        </Typography>
                        <Typography variant='subtitle1'>
                        {inventory.dataCadastro ? renderData(inventory.dataCadastro) : '* não disponível'}
                        </Typography>
                    </Grid>

                    <Grid item xs ={3}>
                        <Typography variant='caption'>
                            Data Última Leitura
                        </Typography>
                        <Typography variant='subtitle1'>
                        {inventory.dataAtualizacao ? renderData(inventory.dataAtualizacao) : '* não disponível'}
                        </Typography>
                    </Grid>

                    <Grid item xs ={2}>
                        <Typography variant='caption'>
                            Latitude
                        </Typography>
                        <Typography variant='subtitle1'>
                        {inventory.Posicao ? inventory.Posicao.latitude : '* não disponível'}
                        </Typography>
                    </Grid>
                    <Grid item xs ={2}>
                        <Typography variant='caption'>
                            Longitude
                        </Typography>
                        <Typography variant='subtitle1'>
                        {inventory.Posicao ? inventory.Posicao.longitude : '* não disponível'}
                        </Typography>
                    </Grid>
                    <Grid item xs ={2}>
                        <Typography variant='caption'>
                            Data posição
                        </Typography>
                        <Typography variant='subtitle1'>
                        {inventory.Posicao ? renderData(inventory.Posicao.dataPosicao) : '* não disponível'}
                        </Typography>
                    </Grid>
                </Grid>
            </div>
            <div style={{padding :'10px',marginTop : '10px', paddingBottom : '10px', borderRadius :'5px', backgroundColor : '#fff'}}> 
            <div style ={{ display:'flex', justifyContent:'space-between'}}>
                <div style ={{width:'60%', }}>
                    <Typography variant='subtitle1'>
                        # Processador(es) 
                    </Typography>
                    <Grid container spacing={1}>
                        <Grid item xs ={4}>
                            <Typography variant='caption' style ={{fontSize :'12px'}}>Modelo</Typography>
                        </Grid>
                        <Grid item xs ={2}>
                            <Typography variant='caption' style ={{fontSize :'12px'}}>Cores</Typography>
                        </Grid>
                        <Grid item xs ={2}>
                            <Typography variant='caption' style ={{fontSize :'12px'}}>Logical</Typography>
                        </Grid>
                        <Grid item xs ={2}>
                            <Typography variant='caption' style ={{fontSize :'12px'}}>Clock</Typography>
                        </Grid>
                        <Grid item xs ={2}>
                            <Typography variant='caption' style ={{fontSize :'12px'}}>Serial</Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                    {
                        inventory.Processors ? inventory.Processors.map(function(processador, index){
                            return <React.Fragment>
                                        <Grid item xs ={4}>
                                            <Typography variant='caption' style ={{fontSize :'12px'}}>{processador.Name}</Typography>
                                        </Grid>
                                        <Grid item xs ={2}>
                                            <Typography variant='caption' style ={{fontSize :'12px'}}>{processador.NumberOfCores}</Typography>
                                        </Grid>
                                        <Grid item xs ={2}>
                                            <Typography variant='caption' style ={{fontSize :'12px'}}>{processador.NumberOfLogicalProcessors}</Typography>
                                        </Grid>
                                        <Grid item xs ={2}>
                                            <Typography variant='caption' style ={{fontSize :'12px'}}>{processador.MaxClockSpeed}</Typography>
                                        </Grid>
                                        <Grid item xs ={2}>
                                            <Typography variant='caption' style ={{fontSize :'12px'}}>{processador.ProcessorId}</Typography>
                                        </Grid>
                                    </React.Fragment>
                        }) : null
                    }
                    </Grid>
                    {
                       inventory.usoProcessador &&  Math.round(inventory.usoProcessador[inventory.usoProcessador.length - 1]) >= 89  ? <Alert severity="error">                     
                        <Typography variant='subtitle2'>* Atenção, uso do processador esta elevado, verifique!</Typography>
                       </Alert> : null
                    }
                    <br/>
                    <Divider/>
                    <br/>
                    <Typography variant='subtitle1'>
                        # Memoria(s) 
                    </Typography>
                    <Grid container spacing={1}>
                        <Grid item xs ={4}>
                            <Typography variant='caption' style ={{fontSize :'12px'}}>Fabricante</Typography>
                        </Grid>
                        <Grid item xs ={4}>
                            <Typography variant='caption' style ={{fontSize :'12px'}}>Serial</Typography>
                        </Grid>
                        <Grid item xs ={2}>
                            <Typography variant='caption' style ={{fontSize :'12px'}}>Capacidade</Typography>
                        </Grid>
                        <Grid item xs ={2}>
                            <Typography variant='caption' style ={{fontSize :'12px'}}>Velocidade</Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                    {
                        inventory.Memory ? inventory.Memory.map(function(memoria, index){
                            return <React.Fragment>
                                        <Grid item xs ={4}>
                                            <Typography variant='caption' style ={{fontSize :'12px'}}>{memoria.Manufacturer}</Typography>
                                        </Grid>
                                        <Grid item xs ={4}>
                                            <Typography variant='caption' style ={{fontSize :'12px'}}>{memoria.PartNumber}</Typography>
                                        </Grid>
                                        <Grid item xs ={2}>
                                            <Typography variant='caption' style ={{fontSize :'12px'}}>{memoria.Capacity}</Typography>
                                        </Grid>
                                        <Grid item xs ={2}>
                                            <Typography variant='caption' style ={{fontSize :'12px'}}>{memoria.Speed}</Typography>
                                        </Grid>
                                        
                                    </React.Fragment>
                        }) : null
                    }
                    </Grid>
                    {
                       inventory.usoMemoria &&  Math.round(inventory.usoMemoria[inventory.usoMemoria.length - 1]) >= 90 ? <Alert severity="error" >
                       
                        <Typography variant='subtitle2'>* Atenção, uso da memória esta no limite, verifique!</Typography>
                       </Alert> : null
                    }
                </div>
                <div style ={{width:'40%', padding :'10px',marginTop : '10px', paddingBottom : '0px', borderRadius :'5px', backgroundColor : '#fff'}}>
                    <div style={{width :'100%', display : 'flex', flexWrap:'wrap-reverse' }}>
                        <div style={{width:'49%',  textAlign:'center'}}> 
                            <Typography variant='subtitle1'>
                                # Uso Processador
                            </Typography>
                            <DisplayToner color={(inventory.usoProcessador ? Math.round(inventory.usoProcessador[inventory.usoProcessador.length - 1]) : 0) < 90 ? '#00695C' : '#B71C1C'} width ={'200px'} height={'200px'} percentual={inventory.usoProcessador ? Math.round(inventory.usoProcessador[inventory.usoProcessador.length - 1]) : 0}/>
                        </div>
                        <div style={{width:'49%',  textAlign:'center'}}>
                            <Typography variant='subtitle1'>
                                # Uso Memoria
                            </Typography>
                            <DisplayToner color={(inventory.usoMemoria ? Math.round(inventory.usoMemoria[inventory.usoMemoria.length - 1]) : 0 )< 90 ? '#283593' : '#B71C1C'} width ={'200px'} height={'200px'} percentual={inventory.usoMemoria ? Math.round(inventory.usoMemoria[inventory.usoMemoria.length - 1]) : 0} descricao={'Memória Total : ' + Math.round(inventory.totalMemoria)+"MB"}/>
                       </div>
                    </div>
                    <div>

                    

                   
                </div>
                
                </div>
                
            </div>
          
            <GraficoUso data = {inventory.dataLog}/>
            </div>
            <div style ={{ padding :'10px',marginTop : '10px', paddingBottom : '20px', borderRadius :'5px', backgroundColor : '#fff'}}>
            
                    <Typography variant='subtitle1'>
                        # Unidades de Disco
                    </Typography>
                   <div>
                   {
                        inventory.Discos && inventory.Discos.map(function(disco, i){
                            return <React.Fragment><div style={{width :'100%', marginTop : '10px'}}>
                                <Typography variant='subtitle2'>{disco.Model}</Typography>
                                <Typography variant='caption'>Size: {bytesToGigabytes(disco.Size)}GB(s)</Typography>
                                {
                                    disco.Partitions && disco.Partitions.map(function(particao, i){
                                        return particao && particao.LogicalDisks.length  > 0 ? <div style={{marginTop :'3px'}}>
                                            <Typography variant='caption'>Partições:</Typography>
                                            {
                                                particao.LogicalDisks && particao.LogicalDisks.filter(logico => logico.DeviceID != null).map(function(unidade, i){
                                                    return unidade ? <div>
                                                        <Typography variant='caption'>Unidade : {unidade.DeviceID}</Typography><br/>
                                                        <Typography variant='caption'>Capacidade : {bytesToGigabytes(unidade.Size)}</Typography><br/>
                                                        <Typography variant='caption'>Livre : {bytesToGigabytes(unidade.FreeSpace)}</Typography><br/>
                                                        <LinearProgress value={(unidade.FreeSpace / unidade.Size) *100} variant='determinate' style ={{height : '15px'}}/>
                                                        <Typography variant='caption'>{Math.floor((unidade.FreeSpace / unidade.Size) *100)}% Livre</Typography>
                                                        </div> : null
                                                })
                                            }

                                        </div> : null
                                    })
                                }
                            </div>
                            <Divider style ={{marginTop : '5px'}}/>
                            </React.Fragment>
                        })
                    }
                   </div>

            
            </div>

            <div style ={{ padding :'10px',marginTop : '10px', paddingBottom : '20px', borderRadius :'5px', backgroundColor : '#fff'}}>
                <Typography variant='subtitle1'># Adaptadores de Rede</Typography>
                <Table className='table table-striped table-hover table-sm table-small' style={{marginTop : '5px'}}>
                    <TableHead>
                        <TableCell>Modelo</TableCell>
                        <TableCell>Endereço MAC</TableCell>
                        <TableCell>Endereço IP</TableCell>
                        <TableCell>Mascara SubRede</TableCell>
                        <TableCell>Stats</TableCell>
                    </TableHead>
                    <TableBody>
                    {
                        inventory.NetworkAdapters && inventory.NetworkAdapters.map(function(rede, i){
                            return <TableRow>
                                    <TableCell>{rede.Description}</TableCell>
                                    <TableCell>{rede.MACAddress}</TableCell>
                                    <TableCell>{rede.IPAddresses}</TableCell>
                                    <TableCell>{rede.SubnetMasks}</TableCell>
                                    <TableCell>{rede.Status}</TableCell>
                               
                            </TableRow>
                        })
                    }
                    </TableBody>
                </Table>
            
            </div>

            <div style ={{ padding :'10px',marginTop : '10px', paddingBottom : '20px', borderRadius :'5px', backgroundColor : '#fff'}}>
            <Grid container spacing={1} style ={{marginTop :'5px'}}>
                <Grid item xs ={12}>
                    <Typography variant='subtitle2'>
                        # Placas de Video
                    </Typography>
                    <Typography variant='subtitle1'>
                    {
                        inventory.VideoAdapters && inventory.VideoAdapters.map(function(video, i){
                            return <React.Fragment>
                                <Typography variant='caption'>Modelo : {video.Description}</Typography><br/>
                                <Typography variant='caption'>Processador : {video.VideoProcessor}</Typography><br/>
                                <Typography variant='caption'>Memória : {bytesToGigabytes(video.VideoMemorySize)} GB</Typography>
                                <br/>
                                <Divider/>
                                <br/>
                            </React.Fragment>
                        })
                    }
                    </Typography>
                </Grid>

            </Grid>
            </div>

            <div style ={{ padding :'10px',marginTop : '10px', paddingBottom : '20px', borderRadius :'5px', backgroundColor : '#fff'}}>
            <Grid container spacing={1} style ={{marginTop :'5px'}}>
                <Grid item xs ={12}>
                    <Typography variant='subtitle2'>
                        # Monitores
                    </Typography>
                    <Typography variant='subtitle1'>
                    {
                        inventory.Monitors && inventory.Monitors.map(function(monitor, i){
                            return <React.Fragment>
                                <Typography variant='caption'>Modelo : {monitor.Name}</Typography><br/>
                                <Typography variant='caption'>Resolução Horizontal : {monitor.ScreenWidth}</Typography><br/>
                                <Typography variant='caption'>Resolução Vertival : {monitor.ScreenHeight}</Typography>
                                <br/>
                                <Divider/>
                                <br/>
                            </React.Fragment>
                        })
                    }
                    </Typography>
                </Grid>

            </Grid>
            </div>
        </div>
        
         
                         
    
        <div className='fabIncluir'>
        {/*<ModalNovoDca idusuario={props.match.params.idempresa} idempresa={props.match.params.chaveEmpresa} getData = {()=>getDcas()}/>*/}
        </div>
    </Principal>
}

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
  }));

export default withRouter(DataInventoryDetails)
import React, {useState, useEffect, useRef, Fragment} from 'react'
import {TextField,  Button,Divider, Container, Grid, Typography, InputAdornment, Table, TableHead, TableBody, TableCell
, TableRow, Card,Switch, FormControlLabel} from '@material-ui/core'
import {Alert, AlertTitle} from '@material-ui/lab'
import EmailIcon from '@material-ui/icons/Email';
import LockIcon from '@material-ui/icons/Lock';
import Conexao from '../../providers/Conexao'
import ConexaoB from '../../providers/ConexaoB'
import Swal from 'sweetalert2'
import {withRouter} from 'react-router-dom'
import Principal from '../Template/TemplateRevenda';
import {Breadcrumbs} from '@material-ui/core'
import {Link } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress';
import ModalFull from '../../components/ModalFull'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import ModalNovoDca from './ModalNovoDca'
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import RemoveIcon from '@material-ui/icons/DeleteForever';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import LabeData from '../../components/LabelData'
import Tooltip from '@material-ui/core/Tooltip';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import PersonIcon from '@material-ui/icons/Person';
import TablePagination from '@material-ui/core/TablePagination';
import SearchIcon from '@material-ui/icons/Cached';
import ClearIcon from '@material-ui/icons/Clear';
import Build from '@material-ui/icons/Build'
import EditarDca from './ModalEdicaoDCA'
import ModalParametrosDca from './ModalParametrosDca';
import ContainerOrderBy from '../../components/ContainerOrderBy';
import Usb from '@material-ui/icons/Usb';
import Brush from '@material-ui/icons/Brush';


const DetalhesDCAConfig = (props)=>{

    const {usuario, dca, disabled, empresa} = props.location.state
    const [loading, setLoading] = useState(false)
    const [timeOutRede, setTimeOutRede] = useState(dca.timeoutrede)
    const [timeOutSNMP, setTimeOutSNMP] = useState(dca.timeoutsnmp)
    const [timeOutWeb, setTimeOutWeb]= useState(dca.timeoutweb)
    const [tentativas, setTentativas]= useState(dca.tentativas)
    const [intervaloColeta, setIntervaloColeta]= useState(dca.intervalocoleta)
    const [enderecosColeta, setEnderecosColeta] = useState([])
    const [multCasts, setMultiCasts]= useState([])
    const [comunidades, setComunidades]= useState([])
    const [monitorarRedes, setMonitorarRedes] = useState(dca.monitarredesconectadas)
    const [comunidade, setComunidade] = useState('')
    const [endereco, setEndereco] = useState('')
    const [rede, setRede] = useState('')
    const [faixaEnderecos, setFaixasEndereco]= useState([])
    const [enderecoInicial, setEnderecoInicial]= useState('')
    const [enderecoFinal, setEnderecoFinal]= useState('')
    const [ativo, setAtivo] = useState(dca.ativo === 't')
    const [serviceCollector, setServiceCollector]= useState(true);
    const [serviceInventory, setServiceInventory]= useState(true);
    

    useEffect(()=>{
       
        if(dca.servicecollector){
            setServiceCollector(dca.servicecollector == 't');
        }

        if(dca.serviceinventory){
            setServiceInventory(dca.serviceinventory == 't');
        }

        if(dca.enderecoscoleta && dca.enderecoscoleta !== ''){
            setEnderecosColeta(dca.enderecoscoleta.split(','))
        }

        if(dca.comunidades && dca.comunidades !== ''){
            setComunidades(dca.comunidades.split(','))
        }

        if(dca.multicast && dca.multicast !== ''){
            setMultiCasts(dca.multicast.split(','))
        }

        if(dca.intervaloscoleta && dca.intervaloscoleta ){
            setFaixasEndereco(dca.intervaloscoleta.split(','))
        }
        setAtivo(dca.ativo === 't')
       
    }, [dca])
    useEffect(()=>{
        console.log(props.location.state)
    },[])
   

 
    const validarIP = (ip) =>{
        // Expressão regular para IPv4
        const regexIPv4 = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
        
        // Expressão regular para IPv6
        const regexIPv6 = /^(?:[a-fA-F0-9]{1,4}:){7}[a-fA-F0-9]{1,4}$/;
        
        // Verifica se o IP corresponde a qualquer uma das expressões regulares
        return regexIPv4.test(ip) || regexIPv6.test(ip);
    }
    const isValidIpFormat = (ip) =>{
        // Função para validar os três primeiros octetos de um endereço IP
        function isValidIpPart(part) {
            const octets = part.split('.');
            if (octets.length !== 3) return false;
            for (let i = 0; i < 3; i++) {
                const num = parseInt(octets[i], 10);
                if (isNaN(num) || num < 0 || num > 255) return false;
            }
            return true;
        }
    
        // Verificar se o endereço termina com ".*"
        if (ip.endsWith('.*')) {
            const ipPart = ip.slice(0, -2); // Remover ".*" para validação dos três primeiros octetos
            return isValidIpPart(ipPart);
        }
        
        return false;
    }
    

    return <Principal atualizar = {loading}  breadcrumbs={
        <Breadcrumbs aria-label="breadcrumb">  
        <Typography>Você esta em:</Typography>          
            <Link color="inherit" to={'#'} >
                Cadastro de DCAs
            </Link>                
        </Breadcrumbs>
    }>
           
        
      
    
       <div className='div-form-container'>
            <Card elevation={2}>
                <div style ={{padding :'10px', paddingBottom :'20px'}}>
                    <Typography variant='h6' className='label-title'><Usb/> Datacount Collector DCA</Typography>
                    <br/>
                    <Divider/>
                    <br/>
                    <Grid container spacing={1}>
                        <Grid item xs = {2}>
                            <Typography variant='caption' className='label-title'>Chave de Instalação</Typography><br/>
                            <Typography variant='subtitle1'>{dca.chave}</Typography>
                        </Grid>
                        <Grid item xs = {2}>
                            <Typography variant='caption' className='label-title'>Versão</Typography><br/>
                            <Typography variant='subtitle1'>{dca.dcaversao}</Typography>
                        </Grid>
                        <Grid item xs = {4}>
                            <Typography variant='caption' className='label-title'>Sistema Operacional</Typography><br/>
                            <Typography variant='subtitle1'>{dca.sistemaoperacional}</Typography>
                        </Grid>
                        <Grid item xs = {4}>
                            <Typography variant='caption' className='label-title'>Hostname</Typography><br/>
                            <Typography variant='subtitle1'>{dca.hostname}</Typography>
                        </Grid>
                    </Grid>

                    <Grid container spacing={1}>
                        <Grid item xs = {6}>
                            <Typography variant='caption' className='label-title'>Cliente</Typography><br/>
                            <Typography variant='subtitle1'>{dca.razaosocial}</Typography>
                        </Grid>
                        <Grid item xs = {2}>
                            <Typography variant='caption' className='label-title'>Cadastro</Typography><br/>
                            <Typography variant='subtitle1'>{dca.datacadastro+' '+(dca.horacadastro ? dca.horacadastro.substr(0,8) : '')}</Typography>
                        </Grid>
                        <Grid item xs = {2}>
                            <Typography variant='caption' className='label-title'>Ativação</Typography><br/>
                            <Typography variant='subtitle1'>{dca.dataativacao ? (dca.dataativacao+' '+(dca.horaativacao ? dca.horaativacao.substr(0,8) : '')): '*Sem informação'}</Typography>
                        </Grid>
                        <Grid item xs = {2}>
                            <Typography variant='caption' className='label-title'>Última Comunicação</Typography><br/>
                            <Typography variant='subtitle1'>{dca.dataatualizacao ? (dca.dataatualizacao+' '+(dca.horaatualizacao ? dca.horaatualizacao.substr(0,8) : '') ): '*Sem informação'}</Typography>
                        </Grid>
                    </Grid>

                    <Grid container spacing={1}>
                        <Grid item xs = {8}>
                            <Typography variant='caption' className='label-title'>Mensagem DCA</Typography><br/>
                            <Typography variant='subtitle1'>{dca.statusatualizacao}</Typography>
                        </Grid>
                        <Grid item xs = {4}>
                            <Typography variant='caption' className='label-title'>Situação DCA</Typography><br/>
                            
                          
                            <Typography variant='subtitle1'>{dca.ativo ? 'DCA ATIVO': 'DCA INATIVO'}</Typography>
                        </Grid>
                        
                    </Grid>

                    <Grid container spacing={1}>
                        <Grid item xs = {2}>
                            <Typography variant='caption' className='label-title'>Printerconnect Service Collector</Typography><br/>
                            <Typography variant='subtitle1'>{dca.servicecollector == 't' ? 'Serviço Habilitado' : 'Serviço Desabilitado'}</Typography>
                        </Grid>
                        <Grid item xs = {6}>
                            <Typography variant='caption' className='label-title'>Status</Typography><br/>
                            <Typography variant='subtitle1'>{dca.statusservicecollector}</Typography>
                        </Grid>
                        <Grid item xs = {4}>
                        <Typography variant='caption' className='label-title'>Data/Hora</Typography><br/>                                                    
                        <Typography variant='subtitle1'>{dca.dataservicecollector ? (dca.dataservicecollector +' '+dca.horaservicecollector) : '' }</Typography>
                        </Grid>
                        
                    </Grid>

                    <Grid container spacing={1}>
                    <Grid item xs = {2}>
                            <Typography variant='caption' className='label-title'>Printerconnect Service Inventory</Typography><br/>
                            <Typography variant='subtitle1'>{(dca.serviceinventory == 't' ? 'Serviço Habilitado' : 'Serviço Desabilitado')}</Typography>
                        </Grid>
                        <Grid item xs = {6}>
                            <Typography variant='caption' className='label-title'>Printerconnect Service Inventory</Typography><br/>
                            <Typography variant='subtitle1'>{dca.statusserviceinventory}</Typography>
                        </Grid>
                        <Grid item xs = {4}>
                            <Typography variant='caption' className='label-title'>Data/Hora</Typography><br/>                                                    
                            <Typography variant='subtitle1'>{dca.dataserviceinventory ? (dca.dataserviceinventory +' '+dca.horaserviceinventory) : '' }</Typography>
                        </Grid>
                        
                    </Grid>
                </div>
            </Card>
            <br/>
            <Card elevation={2}>
            <div style ={{padding :'10px', paddingBottom :'20px'}}>
                    <Typography variant='h6' className='label-title'><Build/> Configurações</Typography>
                    <br/>
                    <Divider/>
                    {
                        disabled ? <Alert>
                        <AlertTitle>Versão não suportada</AlertTitle>
                        <Typography>* Apenas DCA`s na versão final <strong>B</strong> suportam configurações remotas. A versão é definida pelo instalador executado, por este motivo apenas após a instalação é possível fazer a configuração remota.</Typography>
                    </Alert> : null
                    }
                     <Divider/>
                    <br/>
                    <div style={{display:'flex'}}>
                        <div style={{width : '20%', paddingLeft : '10px', paddingRight : '10px'}}>
                            <Typography variant='subtitle1' color='secondary' className='label-title'>Módulo Printer DCA</Typography>
                            <Typography variant='caption'>* Esta configurações faz com que o coletor colete dados de impressoras, scanners, multifuncionais e térmicas</Typography>
                        </div>
                        <div style={{width : '30%'}}>
                            <FormControlLabel control={<Switch checked={serviceCollector} onChange={()=>setServiceCollector(!serviceCollector)}/>} label={ativo ? 'Printer DCA Ativo': 'Printer DCA Inativo'} />                          
                           
                        </div>
                    </div>
                    <br/>

                    <div style={{display:'flex'}}>
                        <div style={{width : '20%', paddingLeft : '10px', paddingRight : '10px'}}>
                            <Typography variant='subtitle1' color='secondary' className='label-title'>Módulo Inventory DCA</Typography>
                            <Typography variant='caption'>* Coletar dados de inventário do computador que estiver instalado e enviar para o Datainventory</Typography>
                        </div>
                        <div style={{width : '30%'}}>
                            <FormControlLabel control={<Switch checked={serviceInventory} onChange={()=>setServiceInventory(!serviceInventory)}/>} label={ativo ? 'Inventory DCA Ativo': 'Inventory DCA Inativo'} />                          
                           
                        </div>
                    </div>

                    <br/>
                    <div style={{display:'flex'}}>
                        
                        <div style={{width : '20%', paddingLeft : '10px', paddingRight : '10px'}}>
                            <Typography variant='subtitle1' color='secondary' className='label-title'>Parametros gerais</Typography>
                            <Typography variant='caption'>* Informe os parametros de coleta: TimeOut de Rede, TimeOut SNMP, TimeOut WEB, Intervalo de Coleta, Tentativas SNMP, Monitorar Placas de Rede.</Typography>
                        </div>
                        <div style={{width : '80%'}}>
                        <Grid container spacing={2}>
                            <Grid item xs = {1}>
                                <Typography variant='caption'>Intervalo</Typography><br/>
                               
                                <TextField
                                    variant='outlined'
                                    disabled={loading || dca.ativo == 'f' || disabled || !serviceCollector} 
                                    size='small'
                                    select
                                    SelectProps={{native:true}}
                                    value={intervaloColeta}
                                    onChange={(e)=>setIntervaloColeta(e.target.value)}
                                    fullWidth>
                                        <option value={60}> 1 hora(s)</option>
                                        <option value={120}>2 hora(s)</option>
                                        <option value={180}>3 hora(s)</option>
                                        <option value={240}>4 hora(s)</option>
                                        <option value={300}>5 hora(s)</option>
                                        <option value={360}>6 hora(s)</option>
                                </TextField>
                      
                            </Grid>
                            <Grid item xs = {1}>
                                <Typography variant='caption'>TimeOut Rede</Typography><br/>  
                                <TextField     
                                    disabled={loading || dca.ativo == 'f' || disabled || !serviceCollector}                             
                                    
                                    variant='outlined'
                                    fullWidth
                                    type="number"   
                                    value={timeOutRede}                     
                                    onChange={(e) => {
                                    const newValue = e.target.value;
                                    // Verificar se a nova entrada é numérica e não excede 3 caracteres
                                    if (/^[0-9]*$/.test(newValue) && newValue.length <= 2) {
                                       setTimeOutRede(e.target.value)
                                    }
                                    }}    
                                    onBlur={()=>{
                                        if(timeOutRede < 10 || timeOutRede > 500){
                                            Swal.fire({
                                                title : 'Datacount', 
                                                text : 'O valor do timeOut deve estar em 10 e 500 milissegundos.', 
                                                icon : 'warning', 
                                                showConfirmButton : true,                                                 
                                            })
                                            setTimeOutRede(10)
                                        }
                                    }}                   
                                    InputLabelProps={{shrink:true}}
                                    size='small'/>                              
                            </Grid>
                            <Grid item xs = {1}>
                                <Typography variant='caption'>TimeOut SNMP</Typography><br/>  
                                <TextField    
                                    disabled={loading || dca.ativo == 'f' || disabled || !serviceCollector}                              
                                    
                                    variant='outlined'
                                    fullWidth
                                    type="number" 
                                    value={timeOutSNMP}                       
                                    onChange={(e) => {
                                    const newValue = e.target.value;
                                    // Verificar se a nova entrada é numérica e não excede 3 caracteres
                                    if (/^[0-9]*$/.test(newValue) && newValue.length <= 3) {
                                        setTimeOutSNMP(e.target.value)
                                    }
                                    }}  
                                    onBlur={()=>{
                                        if(timeOutSNMP < 10 || timeOutSNMP > 500){
                                            Swal.fire({
                                                title : 'Datacount', 
                                                text : 'O valor do timeOut deve estar em 10 e 500 milissegundos.', 
                                                icon : 'warning', 
                                                showConfirmButton : true,                                                 
                                            })
                                            setTimeOutSNMP(10)
                                        }
                                    }}                       
                                    InputLabelProps={{shrink:true}}
                                    size='small'/>                              
                            </Grid>

                            <Grid item xs = {1}>
                                <Typography variant='caption'>TimeOut WEB</Typography><br/>  
                                <TextField    
                                    disabled={loading || dca.ativo == 'f' || disabled || !serviceCollector}                              
                                    
                                    variant='outlined'
                                    fullWidth
                                    type="number"  
                                    value={timeOutWeb}                      
                                    onChange={(e) => {
                                    const newValue = e.target.value;
                                    // Verificar se a nova entrada é numérica e não excede 3 caracteres
                                    if (/^[0-9]*$/.test(newValue) && newValue.length <= 3) {
                                        setTimeOutWeb(e.target.value)  
                                    }
                                    }}   
                                    onBlur={()=>{
                                        if(timeOutWeb < 5 || timeOutWeb > 30){
                                            Swal.fire({
                                                title : 'Datacount', 
                                                text : 'O valor do timeOut deve estar em 10 e 500 milissegundos.', 
                                                icon : 'warning', 
                                                showConfirmButton : true,                                                 
                                            })
                                            setTimeOutWeb(10)
                                        }
                                    }}                      
                                    InputLabelProps={{shrink:true}}
                                    size='small'/>                              
                            </Grid>

                            <Grid item xs = {1}>
                                <Typography variant='caption'>Tentativas</Typography><br/>  
                                <TextField
                                    disabled={loading || dca.ativo == 'f' || disabled || !serviceCollector} 
                                    variant='outlined'
                                    size='small'
                                    select
                                    fullWidth
                                    value={tentativas}
                                    onChange={(e)=>setTentativas(e.target.value)}
                                    SelectProps={{native:true}}
                                    >   
                                        <option>1</option>
                                        <option>2</option>
                                        <option>3</option>
                                        <option>4</option>
                                        <option>5</option>
                                        
                                </TextField>                              
                            </Grid>
                            <Grid item xs = {3}>
                                <Typography variant='caption'>Monitorar todas as redes conectadas</Typography><br/> 
                                <TextField
                                    disabled={loading || dca.ativo == 'f' || disabled || !serviceCollector} 
                                    variant='outlined'
                                    size='small'
                                    select
                                    value={monitorarRedes}
                                    onChange={(e)=>setMonitorarRedes(e.target.value)}
                                    SelectProps={{native:true}}
                                    >   
                                        <option value={'f'}>Não</option>
                                        <option value={'t'}>Sim</option>
                                        
                                </TextField>
                            </Grid>
                        </Grid>
                        </div>
                    </div>

                    <br/>
                    <Divider/>
                    <br/>
                    <div style={{display:'flex'}}>
                        <div style={{width : '20%', paddingLeft : '10px', paddingRight : '10px'}}>
                            <Typography variant='subtitle1' color='secondary' className='label-title'>Comunidades SNMP</Typography>
                            <Typography variant='caption'>* Informe os nomes das comunidades SNMP que estão disponíveis para as varreduras do coletor, verifique no(s) equipamento(s) em qual(ais) comunidade(s) ele(s) está(ão) configurado(s).</Typography>
                        </div>
                        <div style={{width : '30%'}}>
                            <Grid container spacing={1}>
                                <Grid item xs ={10}>
                                    <TextField
                                        label='Comunidade SNMP'
                                        disabled={disabled}
                                        fullWidth
                                        InputLabelProps={{shrink:true}}
                                        variant='outlined'
                                        size='small'
                                        value={comunidade}
                                        onChange={(e)=>setComunidade(e.target.value)}
                                        />
                                </Grid>
                                <Grid item xs ={2}>
                                <Button variant='outlined' className='btn btn-info' disabled={loading || dca.ativo == 'f' || disabled || !serviceCollector} onClick={()=>{
                                    setComunidades([...comunidades, comunidade])
                                    setComunidade('')
                                }}>
                                    <Typography variant='caption'>Adicionar</Typography>
                                </Button>
                                </Grid>
                            </Grid>
                            <br/>
                            <Table className='table table table-striped table-hover table-hovered' >
                                <TableHead>
                                    <TableCell style={{width: '80%'}}>Comunidade SNMP</TableCell>
                                    <TableCell style={{width: '20%'}} align='center'>Remove</TableCell>
                                </TableHead>
                                <TableBody>
                                {
                                        comunidades && comunidades.length > 0 ? comunidades.map(function(comunidade, i){
                                            return <TableRow>
                                            <TableCell style={{width: '80%'}}>{comunidade}</TableCell>
                                            <TableCell style={{width: '20%'}} align='center'>
                                                {!disabled && serviceCollector? <DeleteForeverIcon style={{color: 'red'}} onClick ={()=>{
                                                            setComunidades(comunidades.filter((item, index) => index !== i))
                                                        }}/>: <DeleteForeverIcon style={{color: '#ccc'}} />}
                                            </TableCell>
                                        </TableRow>
                                        }) : <TableRow>
                                        <TableCell colSpan={2}>* Não existe(m) registro(s)</TableCell>
                                    </TableRow>
                                    }
                                </TableBody>
                            </Table>
                            
                        </div>
                    </div>

                    <br/>
                    <br/>
                    <Divider/>
                    <br/>
                    <div style={{display:'flex'}}>
                        <div style={{width : '20%', paddingLeft : '10px', paddingRight : '10px'}}>
                            <Typography variant='subtitle1' color='secondary' className='label-title'>Monitorar redes</Typography>
                            <Typography variant='caption'>* Esta configurações faz com que o coletor faça varreduras em todas as subredes que estiverem cadastradas nesta lista</Typography>
                        </div>
                        <div style={{width : '30%'}}>
                        <Grid container spacing={1}>
                                <Grid item xs ={10}>
                                    <TextField
                                        label='Endereços Multcast'
                                        disabled={disabled}
                                        fullWidth
                                        InputLabelProps={{shrink:true}}
                                        variant='outlined'
                                        size='small'
                                        value={rede}
                                        onChange={(e)=>
                                            setRede(e.target.value)}
                                        />
                                </Grid>
                                <Grid item xs ={2}>
                                <Button variant='outlined' className='btn btn-info' disabled={loading || dca.ativo == 'f' || disabled || !serviceCollector} onClick={()=>{
                                    if(!isValidIpFormat(rede)){
                                        Swal.fire({
                                            title : 'Datacount', 
                                            text : 'Informe uma rede válida, ela deve ser no formato: 192.168.0.*, 10.0.0.*, 172.16.2.*', 
                                            showConfirmButton : false, 
                                            showCancelButton : false, 
                                            timer : 2000, 
                                            icon : 'warning'
                                        })   
                                        setRede('')
                                        return
                                    }
                                    
                                    setMultiCasts([...multCasts, rede])
                                    setRede('')
                                }}>
                                    <Typography variant='caption'>Adicionar</Typography>
                                </Button>
                                </Grid>
                            </Grid>
                            <br/>
                            <Typography variant='caption' style={{fontSize:'10px'}}>* Insira as redes no formato ex: 192.168.0.*, 10.0.0.*, etc</Typography>
                            <Table className='table table table-striped table-hover table-hovered' >
                                <TableHead>
                                    <TableCell style={{width: '80%'}}>Rede(s)</TableCell>
                                    <TableCell style={{width: '20%'}} align='center'>Remove</TableCell>
                                </TableHead>
                                <TableBody>
                                {
                                        multCasts && multCasts.length > 0 ? multCasts.map(function(multcast, i){
                                            return <TableRow>
                                            <TableCell style={{width: '80%'}}>{multcast}</TableCell>
                                            <TableCell style={{width: '20%'}} align='center'>
                                                { !disabled && serviceCollector ? <DeleteForeverIcon style={{color: 'red'}} onClick ={()=>{
                                                            setMultiCasts(multCasts.filter((item, index) => index !== i))
                                                        }}/> : <DeleteForeverIcon style={{color: '#ccc'}} />}
                                            </TableCell>
                                        </TableRow>
                                        }) : <TableRow>
                                        <TableCell colSpan={2}>* Não existe(m) registro(s)</TableCell>
                                    </TableRow>
                                    }
                                </TableBody>
                            </Table>
                           
                        </div>
                    </div>
                    <br/>
                    <Divider/>
                    <br/>
                    <div style={{display:'flex'}}>
                        <div style={{width : '20%', paddingLeft : '10px', paddingRight : '10px'}}>
                            <Typography variant='subtitle1' color='secondary' className='label-title'>Intervalos de Rede</Typography>
                            <Typography variant='caption'>* Insira faixas de endereços (inicio e fim) para fazer coleta.</Typography>
                        </div>
                        <div style={{width : '30%'}}>
                        <Grid container spacing={1}>
                                <Grid item xs ={5}>
                                    <TextField
                                        label='Endereço Inicial'
                                        fullWidth
                                       
                                        InputLabelProps={{shrink:true}}
                                        variant='outlined'
                                        size='small'
                                        value={enderecoInicial}
                                        onChange={(e)=>setEnderecoInicial(e.target.value)}
                                        />
                                </Grid>
                                <Grid item xs ={5}>
                                    <TextField
                                        label='Endereço Final'
                                        fullWidth
                               
                                        InputLabelProps={{shrink:true}}
                                        variant='outlined'
                                        size='small'
                                        value={enderecoFinal}
                                        onChange={(e)=>setEnderecoFinal(e.target.value)}
                                        />
                                </Grid>
                                <Grid item xs ={2}>
                                <Button variant='outlined' className='btn btn-info' disabled={loading || dca.ativo == 'f' || disabled || !serviceCollector} onClick={()=>{
                                    if(validarIP(enderecoInicial) && validarIP(enderecoFinal)){
                                        setFaixasEndereco([...faixaEnderecos, enderecoInicial+'-'+enderecoFinal])
                                        setEnderecoInicial('')
                                        setEnderecoFinal('')
                                    }else{
                                        Swal.fire({
                                            title : 'Datacount', 
                                            text : 'O endereço inicial e final precisa estar em um formato válido!!', 
                                            icon : 'warning', 
                                            showCancelButton : false,
                                            showConfirmButton : false, 
                                            timer : 2000
                                        })
                                    }
                                    
                                }}>
                                    <Typography variant='caption'>Adicionar</Typography>
                                </Button>
                                </Grid>
                            </Grid>
                            <br/>
                            <Table className='table table table-striped table-hover table-hovered' >
                                <TableHead>
                                    <TableCell style={{width: '80%'}}>Intervalos de rede</TableCell>
                                    <TableCell style={{width: '20%'}} align='center'>Remove</TableCell>
                                </TableHead>
                                <TableBody>
                                    {
                                        faixaEnderecos && faixaEnderecos.length > 0 ? faixaEnderecos.map(function(endereco, i){
                                            return <TableRow>
                                            <TableCell style={{width: '80%'}}>{endereco}</TableCell>
                                            <TableCell style={{width: '20%'}} align='center'>
                                               {!disabled  && serviceCollector? <DeleteForeverIcon style={{color: 'red'}} onClick ={()=>{
                                                            setFaixasEndereco(faixaEnderecos.filter((item, index) => index !== i))
                                                        }}/>: <DeleteForeverIcon style={{color: '#ccc'}} />}
                                            </TableCell>
                                        </TableRow>
                                        }) : <TableRow>
                                        <TableCell colSpan={2}>* Não existe(m) registro(s)</TableCell>
                                    </TableRow>
                                    }
                                    
                                </TableBody>
                            </Table>
                          
                        </div>
                    </div>


                   
                    <Divider/>
                    <br/>
                    <div style={{display:'flex'}}>
                        <div style={{width : '20%', paddingLeft : '10px', paddingRight : '10px'}}>
                            <Typography variant='subtitle1' color='secondary' className='label-title'>Endereço IP/Hostname</Typography>
                            <Typography variant='caption'>* Insira na lista todos os endereços IP ou HostName que deseja monitorar, a cada ciclo de coleta o coletor os acessará e coletará informações (Caso esteja ligado e seja compativel).</Typography>
                        </div>
                        <div style={{width : '30%'}}>
                        <Grid container spacing={1}>
                                <Grid item xs ={10}>
                                    <TextField
                                        label='Endereço IP/Hostname'
                                        fullWidth
                                        disabled={disabled}
                                        InputLabelProps={{shrink:true}}
                                        variant='outlined'
                                        size='small'
                                        value={endereco}
                                        onChange={(e)=>setEndereco(e.target.value)}
                                        />
                                </Grid>
                                <Grid item xs ={2}>
                                <Button variant='outlined' className='btn btn-info' disabled={loading || dca.ativo == 'f' || disabled || !serviceCollector} onClick={()=>{
                                    
                                    setEnderecosColeta([...enderecosColeta, endereco])
                                    setEndereco('')
                                }}>
                                    <Typography variant='caption'>Adicionar</Typography>
                                </Button>
                                </Grid>
                            </Grid>
                            <br/>
                            <Table className='table table table-striped table-hover table-hovered' >
                                <TableHead>
                                    <TableCell style={{width: '80%'}}>Endereço IP/Hostname</TableCell>
                                    <TableCell style={{width: '20%'}} align='center'>Remove</TableCell>
                                </TableHead>
                                <TableBody>
                                    {
                                        enderecosColeta && enderecosColeta.length > 0 ? enderecosColeta.map(function(endereco, i){
                                            return <TableRow>
                                            <TableCell style={{width: '80%'}}>{endereco}</TableCell>
                                            <TableCell style={{width: '20%'}} align='center'>
                                               {!disabled && serviceCollector? <DeleteForeverIcon style={{color: 'red'}} onClick ={()=>{
                                                            setEnderecosColeta(enderecosColeta.filter((item, index) => index !== i))
                                                        }}/>: <DeleteForeverIcon style={{color: '#ccc'}} />}
                                            </TableCell>
                                        </TableRow>
                                        }) : <TableRow>
                                        <TableCell colSpan={2}>* Não existe(m) registro(s)</TableCell>
                                    </TableRow>
                                    }
                                    
                                </TableBody>
                            </Table>
                          
                        </div>
                    </div>

                    <br/>
                    <Divider/>
                    <br/>
                    

                    <Divider/>
                    <br/>
                    <div style={{display:'flex'}}>
                        <div style={{width : '20%', paddingLeft : '10px', paddingRight : '10px'}}>
                            <Typography variant='subtitle1' color='secondary' className='label-title'>Status do DCA</Typography>
                            <Typography variant='caption'>* Esta configurações faz com que o coletor pare de responder (coletar) definitivamente, não há como reverter essa ação</Typography>
                        </div>
                        <div style={{width : '30%'}}>
                            <FormControlLabel control={<Switch checked={ativo} onChange={()=>setAtivo(!ativo)}/>} label={ativo ? 'DCA ATIVO': 'DCA INATIVO'} />                          
                           
                        </div>
                    </div>

                   


                    
                    <br/>
                    <Divider/>
                    <br/>
                    <div style={{display:'flex'}}>
                        <div style={{width : '20%', paddingLeft : '10px', paddingRight : '10px'}}>
                            
                            <Typography variant='caption'>* Confirme ou cancele as alterações feitas na configuração do DCA</Typography>
                        </div>
                        <div style={{width : '30%', textAlign : 'right'}}>
                          <Button className='btn btn-warning' disabled={loading} onClick={()=>{
                             props.history.push({pathname : '/'+props.location.state.goBack+'/revenda/'+props.location.state.idusuario+'/empresa/'+props.location.state.idempresa+'/page/1', state : {
                                empresa : props.location.state.empresa,
                                usuario : props.location.state.usuario, 
                                orderBy : props.location.state.orderBy, 
                                orderDirection : props.location.state.orderDirection
                              }})
                          }}>
                            <Typography>Cancelar</Typography>
                          </Button>
                          <Button disabled={loading || dca.ativo == 'f'} className='btn btn-success'  style={{ marginLeft : '10px'}} onClick={()=>{
                            if(ativo){
                                if(comunidades.length === 0){
                                    Swal.fire({
                                        title : 'Datacount', 
                                        text :'Você precisa informar pelo menos um nome de comunidade', 
                                        icon : 'warning', 
                                        showCancelButton : false, 
                                        cancelButtonText : 'Não',
                                        showConfirmButton : false, 
                                        confirmButtonText : 'Sim', 
                                        timer : 2500
                                    })
                                    return;
                                }
    /*
                                if((enderecosColeta.length === 0 && multCasts.length === 0 && faixaEnderecos.length == 0) ){
                                    Swal.fire({
                                        title : 'Datacount', 
                                        text :'Você precisa informar pelo menos um endereço IP/Hostname, um endereço Multicast/rede ou um intervalo de rede', 
                                        icon : 'warning', 
                                        showCancelButton : false, 
                                        cancelButtonText : 'Não',
                                        showConfirmButton : false, 
                                        confirmButtonText : 'Sim', 
                                        timer : 2500
                                    })
                                    return;
                                }
                                    */
                            }
                            


                                Swal.fire({
                                    title : 'Datacount', 
                                    text :'Deseja gravas as configurações informadas?', 
                                    icon : 'question', 
                                    showCancelButton : true, 
                                    cancelButtonText : 'Não',
                                    showConfirmButton : true, 
                                    confirmButtonText : 'Sim'
                                }).then(resp=>{
                                    if(resp.isConfirmed){
                                        setLoading(true)
                                        const dataSend = {                                           
                                            idusuario : dca.idusuario, 
                                            idempresa : dca.idempresa, 
                                            idcliente : dca.idcliente,
                                            chave : dca.chave, 
                                            monitarredesconectadas : monitorarRedes, 
                                            timeoutweb : timeOutWeb,
                                            timeoutrede : timeOutRede, 
                                            timeoutsnmp : timeOutSNMP, 
                                            tentativas : tentativas , 
                                            comunidades :comunidades && comunidades !== '' ?  comunidades.join(',') : '', 
                                            intervalocoleta :intervaloColeta  , 
                                            enderecoscoleta : enderecosColeta && enderecosColeta !== '' ? enderecosColeta.join(',') : '', 
                                            multcasts : multCasts && multCasts !== ''  ? multCasts.join(',') : '', 
                                            intervaloColeta : faixaEnderecos && faixaEnderecos != '' ? faixaEnderecos.join(',') : '',
                                            ativo : ativo ? 't' : 'f', 
                                            serviceCollector : !ativo ? 'f' : (serviceCollector ? 't' : 'f'), 
                                            serviceInventory : !ativo ? 'f' : (serviceInventory ? 't' : 'f')                                     
                                        }
                                        Conexao.post('dcaController/updateConfigDca', {
                                            ...dataSend
                                        }).then(response=>{
                                            if(response.status == 200 && response.data.status == 'OK'){
                                                Swal.fire({
                                                    title : 'Datacount', 
                                                    text : 'Configurações atualizadas com sucesso', 
                                                    showConfirmButton : false, 
                                                    showCancelButton : false, 
                                                    timer : 2000, 
                                                    icon : 'success'
                                                });
                                                setTimeout(()=>{
                                                    setLoading(false)
                                           
                                                    props.history.push({pathname : '/'+props.location.state.goBack+'/revenda/'+props.location.state.idusuario+'/empresa/'+props.location.state.idempresa+'/page/1', state : {
                                                        empresa : props.location.state.empresa,
                                                        usuario : props.location.state.usuario, 
                                                        orderBy : props.location.state.orderBy, 
                                                        orderDirection : props.location.state.orderDirection
                                                      }})
                                                }, 2000)
                                            }
                                        }).catch(error=>{
                                            setLoading(false)
                                            Swal.fire({
                                                title : 'Datacount', 
                                                text : 'Não foi possível atualizar as configurações', 
                                                showConfirmButton : false, 
                                                showCancelButton : false, 
                                                timer : 2000, 
                                                icon : 'errro'
                                            });
                                        })
                                    }
                                })
                          }}>
                            <Typography>Gravar</Typography>
                          </Button>
                        </div>
                    </div>
            </div>
            </Card>
        </div>                  
    
   
    </Principal>
}

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
  }));

export default withRouter(DetalhesDCAConfig)